import React, { FC } from 'react';
import { PageLayout } from '../../components/layout';
import {
  SolutionsHero,
  SolutionsOurSolutions,
  SolutionsTestimonials,
  SolutionsMakeAnAppointment,
} from '../../components/solutions';

const Page: FC = () => {
  /**
   * DOM
   */
  return (
    <PageLayout bgCls="bg-accent-light">
      <SolutionsHero />
      <SolutionsOurSolutions />
      <SolutionsTestimonials />
      <SolutionsMakeAnAppointment />
    </PageLayout>
  );
};

export default Page;
